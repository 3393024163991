import request from '@/utils/request'
// 获取资讯信息列表-居民端
export function WeGetRealInfoPage(query) {
  return request({
    url: 'RealInfo/WeGetRealInfoPage',
    method: 'get',
    params: query,
  })
}
// 获取资讯信息-居民端
export function WeGetRealInfo(query) {
  return request({
    url: 'PartyInfo/WeGetRealInfo',
    method: 'get',
    params: query,
  })
}
//   @click="gotoUrl('/fivestar/wuXingXq/' + item.AId + '-1')"
// 居民端小程序-获取活动详情
export function WxGetActivityDetail(query) {
  return request({
    url: 'Activity/WxGetActivityDetail',
    method: 'get',
    params: query,
  })
}

// 小程序-报名参与活动
export function WxEnrolPBActivity(data) {
  return request({
    url: 'Activity/WxEnrolPBActivity',
    method: 'post',
    data: data,
  })
}

// 小程序-获取社区活动分页列表
export function WeGetActivityPage(query) {
  return request({
    url: 'Activity/WeGetActivityPage',
    method: 'get',
    params: query,
  })
}
// 获取社区活动详情
export function WeGetActivityDetail(query) {
  return request({
    url: 'Activity/WeGetActivityDetail',
    method: 'get',
    params: query,
  })
}
// 小程序-报名参与社区活动
export function WeEnrolActivity(data) {
  return request({
    url: 'Activity/WeEnrolActivity',
    method: 'post',
    data: data,
  })
}
// 小程序获取商铺分页列表
export function WeGetShopPage(query) {
  return request({
    url: 'Shop/WeGetShopPage',
    method: 'get',
    params: query,
  })
}
// 获取资讯信息列表-党员端
export function WeGetPartyInfoPage(query) {
  return request({
    url: 'PartyInfo/WeGetPartyInfoPage',
    method: 'get',
    params: query,
  })
}
// 获取党员积分分页列表[党员端]
export function WeGetPMIntegralPage(query) {
  return request({
    url: 'Party/WeGetPMIntegralPage',
    method: 'get',
    params: query,
  })
}
// 小程序-获取党组活动分页列表
export function WeGetMyActivityPage(query) {
  return request({
    url: 'PBActivity/WeGetMyActivityPage',
    method: 'get',
    params: query,
  })
}
// 小程序-获取党组活动分页列表
export function WxGetMyActivityPage(query) {
  return request({
    url: 'Activity/WxGetMyActivityPage',
    method: 'get',
    params: query,
  })
}
// 获取资讯信息-党员端
export function WeGetPartyInfo(query) {
  return request({
    url: 'PartyInfo/WeGetPartyInfo',
    method: 'get',
    params: query,
  })
}
// 小程序党建大事件时间轴列表
export function WxGetDateAxisList(query) {
  return request({
    url: 'PartyInfo/WxGetDateAxisList',
    method: 'get',
    params: query,
  })
}
// 居民端-获取我的商铺列表
export function WxGetMyShopList(query) {
  return request({
    url: 'Shop/WxGetMyShopList',
    method: 'get',
    params: query,
  })
}

// 居民端-重新绑定微信
export function WxReBindShop(data) {
  return request({
    url: 'Shop/WxReBindShop',
    method: 'post',
    data,
  })
}

// 居民端-获取我的企业信息列表
export function WxGetMyEnterpriseList(query) {
  return request({
    url: 'Enterprise/WxGetMyEnterpriseList',
    method: 'get',
    params: query,
  })
}
// 居民端-获取单位从业人员列表
export function WxGetEntStaffPage(query) {
  return request({
    url: 'Staff/WxGetEntStaffPage',
    method: 'get',
    params: query,
  })
}
// 居民端-保存从业人员信息
export function WxSaveStaff(data) {
  return request({
    url: 'Staff/WxSaveStaff',
    method: 'post',
    data,
  })
}

// 获取从业人员详情
export function GetStaff(query) {
  return request({
    url: 'Staff/GetStaff',
    method: 'get',
    params: query,
  })
}

// 删除员工信息
export function WxDeleteStaff(data) {
  return request({
    url: 'Staff/WxDeleteStaff',
    method: 'post',
    data,
  })
}
// 居民端-获取我的隔离登记信息列表
export function WxGetIsolationPage(query) {
  return request({
    url: 'Isolation/WxGetIsolationPage',
    method: 'get',
    params: query,
  })
}

// 居民端-我的返洛排查列表
export function WxGetReportingPage(query) {
  return request({
    url: 'Vaccine/WxGetReportingPage',
    method: 'get',
    params: query,
  })
}

// 居民端-我的疫苗排查列表
export function WxGetCheckPage(query) {
  return request({
    url: 'Vaccine/WxGetCheckPage',
    method: 'get',
    params: query,
  })
}

// 居民端-更新疫苗接种状态
export function WxUpdateVaccinateStatus(data) {
  return request({
    url: 'Vaccine/WxUpdateVaccinateStatus',
    method: 'post',
    data,
  })
}

// 居民端-我的返洛排查列表
export function WxGetOutReportingPage(query) {
  return request({
    url: 'Vaccine/WxGetOutReportingPage',
    method: 'get',
    params: query,
  })
}
// 居民端-社区活动分类
export function WeGetPBActivityPage(query) {
  return request({
    url: 'PBActivityKind/WeGetPBActivityPage',
    method: 'get',
    params: query,
  })
}
// 小程序-获取社区活动分页列表
export function WePBGetActivityPage(query) {
  return request({
    url: 'PBActivity/WeGetActivityPage',
    method: 'get',
    params: query,
  })
}

// 获取社区活动详情
export function WeGetPBActivityDetail(query) {
  return request({
    url: 'PBActivity/WeGetActivityDetail',
    method: 'get',
    params: query,
  })
}

// 小程序-报名参与社区活动
export function WePBEnrolActivity(data) {
  return request({
    url: 'PBActivity/WeEnrolPBActivity',
    method: 'post',
    data: data,
  })
}

// 小程序-获取社区资讯分类
export function WeGetInfoKindInfoPage(query) {
  return request({
    url: 'InfoKind/WeGetInfoKindInfoPage',
    method: 'get',
    params: query,
  })
}

// 移动端-获取社区活动分类信息列表
export function WxGetAKindList(query) {
  return request({
    url: 'ActKind/WxGetAKindList',
    method: 'get',
    params: query,
  })
}

// 居民端小程序-获取招募中的活动分页列表
export function WxGetRecruitActivityPage(query) {
  return request({
    url: 'Activity/WxGetRecruitActivityPage',
    method: 'get',
    params: query,
  })
}

// 获取资讯信息列表-居民端
export function WePBGetRealInfoPage(query) {
  return request({
    url: 'PartyInfo/WeGetRealInfoPage',
    method: 'get',
    params: query,
  })
}

// 发布活动记录【党建小程序使用】
export function WeRecActLogs(data) {
  return request({
    url: 'PBActivity/WeRecActLogs',
    method: 'post',
    data: data,
  })
}

// 获取党组活动详情
export function WeGetActivityDetailPB(query) {
  return request({
    url: 'PBActivity/WeGetActivityDetail',
    method: 'get',
    params: query,
  })
}
// 获取商品分页列表
export function WxGetMyGoodsPage(query) {
  return request({
    url: 'Goods/WxGetMyGoodsPage',
    method: 'get',
    params: query,
  })
}
// 获取商品详情
export function WxGetMyGoods(query) {
  return request({
    url: 'Goods/WxGetMyGoods',
    method: 'get',
    params: query,
  })
}

// 场所端-获取美食详情
export function WxGetMyFood(query) {
  return request({
    url: 'Food/WxGetMyFood',
    method: 'get',
    params: query,
  })
}
// 获取商品列表
export function WxGetGoodsPage(query) {
  return request({
    url: 'Goods/WxGetGoodsPage',
    method: 'get',
    params: query,
  })
}

// 获取商品详情
export function WxGetGoods(query) {
  return request({
    url: 'Goods/WxGetGoods',
    method: 'get',
    params: query,
  })
}

// 保存商品
export function WxMySaveGoodsSort(data) {
  return request({
    url: 'Goods/WxMySaveGoodsSort',
    method: 'post',
    data: data,
  })
}
// 删除商品
export function WxMyDeleteGoods(data) {
  return request({
    url: 'Goods/WxMyDeleteGoods',
    method: 'post',
    data: data,
  })
}

// 场所端-保存美食
export function WxSaveMyFood(data) {
  return request({
    url: 'Food/WxSaveMyFood',
    method: 'post',
    data: data,
  })
}
// 获取商品类别列表
export function WxGetGoodsCateList(query) {
  return request({
    url: 'GoodsCate/WxGetGoodsCateList',
    method: 'get',
    params: query,
  })
}

// 获取商品分类列表
export function WxGetGoodsSortList(query) {
  return request({
    url: 'GoodsSort/WxGetGoodsSortList',
    method: 'get',
    params: query,
  })
}

// 获取商品分类列表
export function WxGetMyGoodsSortList(query) {
  return request({
    url: 'GoodsSort/WxGetMyGoodsSortList',
    method: 'get',
    params: query,
  })
}
// 保存商品分类
export function WxSaveGoodsSort(data) {
  return request({
    url: 'GoodsSort/WxSaveGoodsSort',
    method: 'post',
    data: data,
  })
}

// 删除商品
export function WxDeleteGoodsSort(data) {
  return request({
    url: 'GoodsSort/WxDeleteGoodsSort',
    method: 'post',
    data: data,
  })
}

// 场所端-删除食品分类
export function WxDeleteFoodCate(data) {
  return request({
    url: 'FoodCate/WxDeleteFoodCate',
    method: 'post',
    data: data,
  })
}
// 获取商品分类详情
export function WxGetMyGoodsSort(query) {
  return request({
    url: 'GoodsSort/WxGetMyGoodsSort',
    method: 'get',
    params: query,
  })
}

// 获取商品订单
export function WxGetShopOrderPage(query) {
  return request({
    url: 'ShopOrder/WxGetShopOrderPage',
    method: 'get',
    params: query,
  })
}

// 接收订单，居民端商铺使用
export function WxRecviceOrder(data) {
  return request({
    url: 'ShopOrder/WxRecviceOrder',
    method: 'post',
    data: data,
  })
}

// 场所端-接收订单
export function WxRecviceMessOrder(data) {
  return request({
    url: 'MessOrder/WxRecviceOrder',
    method: 'post',
    data: data,
  })
}

// 结单，居民端商铺使用
export function WxFinishOrder(data) {
  return request({
    url: 'ShopOrder/WxFinishOrder',
    method: 'post',
    data: data,
  })
}

// 场所端-结单
export function WxFinishMessOrder(data) {
  return request({
    url: 'MessOrder/WxFinishOrder',
    method: 'post',
    data: data,
  })
}

export function GetActivityPage(query) {
  return request({
    url: 'PBActivity/GetActivityPage',
    method: 'get',
    params: query,
  })
}
// 获取七牛云token
export function UploadQiniuUpToken(data) {
  return request({
    url: 'UploadFile/UploadQiniuUpToken',
    method: 'post',
    data,
  })
}
//七牛云视频上传
export function UploadQiniuVideo(data) {
  return request({
    url: 'UploadFile/UploadQiniuVideo',
    method: 'post',
    data,
  })
}
// 获取促销活动列表-商家
export function WxGetMyPromotionPage(query) {
  return request({
    url: 'Promotion/WxGetMyPromotionPage',
    method: 'get',
    params: query,
  })
}
// 获取促销活动详情-商家
export function WxGetMyPromotion(query) {
  return request({
    url: 'Promotion/WxGetMyPromotion',
    method: 'get',
    params: query,
  })
}
// 保存促销活动-商铺
export function WxSaveMyPromotion(data) {
  return request({
    url: 'Promotion/WxSaveMyPromotion',
    method: 'post',
    data,
  })
}

// 删除促销活动
export function WxDeleteMyPromotion(data) {
  return request({
    url: 'Promotion/WxDeleteMyPromotion',
    method: 'post',
    data,
  })
}

// 获取促销活动详情-居民
export function WxGetPromotion(query) {
  return request({
    url: 'Promotion/WxGetPromotion',
    method: 'get',
    params: query,
  })
}

// 参加促销活动-居民
export function WxEnrolPromotion(data) {
  return request({
    url: 'Promotion/WxEnrolPromotion',
    method: 'post',
    data,
  })
}

// 获取我的促销活动报名列表
export function WxGetActEnrolPage(query) {
  return request({
    url: 'Promotion/WxGetActEnrolPage',
    method: 'get',
    params: query,
  })
}

// 获取促销活动报名列表
export function WxGetMyActEnrolPage(query) {
  return request({
    url: 'Promotion/WxGetMyActEnrolPage',
    method: 'get',
    params: query,
  })
}

// 获取核酸检测报备信息列表
export function WxGetNATestPage(query) {
  return request({
    url: 'NATest/WxGetNATestPage',
    method: 'get',
    params: query,
  })
}
// 获取会员信息
export function WeGetMemberByOpenID(query) {
  return request({
    url: 'Member/WeGetMemberByOpenID',
    method: 'get',
    params: query,
  })
}
// 获取公众号文章列表
export function WxGetWxArticlePage(query) {
  return request({
    url: 'WxArticle/WxGetWxArticlePage',
    method: 'get',
    params: query,
  })
}
// 获取公众号文章详情
export function WxGetWxArticle(query) {
  return request({
    url: 'WxArticle/WxGetWxArticle',
    method: 'get',
    params: query,
  })
}

// 通过OpenId获取会员详情
export function WeMyMemberInfo(query) {
  return request({
    url: 'Member/WeMyMemberInfo',
    method: 'get',
    params: query,
  })
}

// 获取邻里中心 详情
export function WxGetNeInfoOfOrgan(query) {
  return request({
    url: 'NeInfo/WxGetNeInfoOfOrgan',
    method: 'get',
    params: query,
  })
}

// 获取邻里中心 详情
export function WxGetNeInfo(query) {
  return request({
    url: 'NeInfo/WxGetNeInfo',
    method: 'get',
    params: query,
  })
}

// 获取商铺类型列表
export function WxGetShopTypeList(query) {
  return request({
    url: 'Shop/WxGetShopTypeList',
    method: 'get',
    params: query,
  })
}

// 获取商铺类型列表
export function WxGetShopPage(query) {
  return request({
    url: 'Shop/WxGetBankPage',
    method: 'get',
    params: query,
  })
}

// 商城端-获取我的商城列表
export function WxGetMyBankList(query) {
  return request({
    url: 'Shop/WxGetMyBankList',
    method: 'get',
    params: query,
  })
}
// 评价服务订单
export function WeEvaluateOrder(data) {
  return request({
    url: 'OrderEval/WeEvaluateOrder',
    method: 'post',
    data,
  })
}

// 居民端-评价食堂订单
export function WxEvaluateOrder(data) {
  return request({
    url: 'MessOrderEval/WxEvaluateOrder',
    method: 'post',
    data,
  })
}
// 居民端-获取商品订单
export function WeGetMyOrderPage(query) {
  return request({
    url: 'ShopOrder/WeGetMyOrderPage',
    method: 'get',
    params: query,
  })
}

// 场所端-获取食堂订单列表
export function WxGetOrderPage(query) {
  return request({
    url: 'MessOrder/WxGetOrderPage',
    method: 'get',
    params: query,
  })
}

// 居民端-获取我的订单列表
export function WxGetMyOrderPage(query) {
  return request({
    url: 'MessOrder/WxGetMyOrderPage',
    method: 'get',
    params: query,
  })
}
// 商城端-获取我的商城详情
export function WxGetBank(query) {
  return request({
    url: 'Shop/WxGetBank',
    method: 'get',
    params: query,
  })
}

// 居民端-获取指定邻里中心的场所列表分页列表
export function WxGetFnRoomPage(params) {
  return request({
    url: 'FnRoom/WxGetFnRoomPage',
    method: 'get',
    params,
  })
}

// 居民端-获取指定邻里中心的房源分页列表
export function WxGetLeasePage(params) {
  return request({
    url: 'Lease/WxGetLeasePage',
    method: 'get',
    params,
  })
}

export function WxGetFoodCateList(params) {
  return request({
    url: 'FoodCate/WxGetFoodCateList',
    method: 'get',
    params,
  })
}

// 居民端-获取美食列表
export function WxGetFoodPage(params) {
  return request({
    url: 'Food/WxGetFoodPage',
    method: 'get',
    params,
  })
}

// 居民端-获取美食详情
export function WxGetFood(params) {
  return request({
    url: 'Food/WxGetFood',
    method: 'get',
    params,
  })
}

// 居民端-获取我的房源详情
export function WxGetLease(params) {
  return request({
    url: 'Lease/WxGetLease',
    method: 'get',
    params,
  })
}

// 居民端-预约看房
export function WxPreRoom(data) {
  return request({
    url: 'PreRoom/WxPreRoom',
    method: 'post',
    data,
  })
}

// 食堂端-获取我的食堂列表
export function WxGetMyFnRoomList(query) {
  return request({
    url: 'FnRoom/WxGetMyFnRoomList',
    method: 'get',
    params: query,
  })
}

// 场所端-获取美食列表
export function WxGetMyFoodPage(query) {
  return request({
    url: 'Food/WxGetMyFoodPage',
    method: 'get',
    params: query,
  })
}

// 场所端-删除美食
export function WxDeleteMyFood(data) {
  return request({
    url: 'Food/WxDeleteMyFood',
    method: 'post',
    data,
  })
}

// 获取美食分类列表
export function WxGetMyFoodCateList(query) {
  return request({
    url: 'FoodCate/WxGetMyFoodCateList',
    method: 'get',
    params: query,
  })
}

// 场所端-保存食品分类
export function WxSaveFoodCate(data) {
  return request({
    url: 'FoodCate/WxSaveFoodCate',
    method: 'post',
    data,
  })
}

// 场所端-获取食品分类详情
export function WxGetMyFoodCate(query) {
  return request({
    url: 'FoodCate/WxGetMyFoodCate',
    method: 'get',
    params: query,
  })
}

// 房主端-获取我的房源
export function WxGetMyLeaseList(query) {
  return request({
    url: 'Lease/WxGetMyLeaseList',
    method: 'get',
    params: query,
  })
}
// 房主端-保存我的房源信息，用于新增或修改房源
export function WxSaveMyLease(data) {
  return request({
    url: 'Lease/WxSaveMyLease',
    method: 'post',
    data,
  })
}
// 房主端-获取指定房源的预约单列表
export function WxGetPreRoomPage(params) {
  return request({
    url: 'PreRoom/WxGetPreRoomPage',
    method: 'get',
    params,
  })
}

// 房主端-处理看房预约
export function WxDealPreRoom(data) {
  return request({
    url: 'PreRoom/WxDealPreRoom',
    method: 'post',
    data,
  })
}

// 居民端-获取我的看房预约单列表
export function WxGetMyPreRoomPage(params) {
  return request({
    url: 'PreRoom/WxGetMyPreRoomPage',
    method: 'get',
    params,
  })
}

// 居民端-删除看房预约单
export function WxDeletePreRoom(data) {
  return request({
    url: 'PreRoom/WxDeletePreRoom',
    method: 'post',
    data,
  })
}

// 场所列表端-保存我的场所列表信息，用于新增或修改场所列表
export function WxSaveMyFnRoom(data) {
  return request({
    url: 'FnRoom/WxSaveMyFnRoom',
    method: 'post',
    data,
  })
}

// 场所端-获取服务场所预约单列表
export function WxGetPreBookPage(query) {
  return request({
    url: 'PreBook/WxGetPreBookPage',
    method: 'get',
    params: query,
  })
}

// 场所端-接收预约
export function WxRecvicePreBook(data) {
  return request({
    url: 'PreBook/WxRecvicePreBook',
    method: 'post',
    data,
  })
}

// 场所端-接收预约
export function WxRefsusePreBook(data) {
  return request({
    url: 'PreBook/WxRefsusePreBook',
    method: 'post',
    data,
  })
}
// 场所端-完成预约服务
export function WxFinishPreBook(data) {
  return request({
    url: 'PreBook/WxFinishPreBook',
    method: 'post',
    data,
  })
}

// 居民端-撤销订单
export function WxRevokeOrder(data) {
  return request({
    url: 'MessOrder/WxRevokeOrder',
    method: 'post',
    data,
  })
}

// 居民端-删除订单
export function WxDeleteOrder(data) {
  return request({
    url: 'MessOrder/WxDeleteOrder',
    method: 'post',
    data,
  })
}

// 居民端-获取我的预约单列表
export function WxGetMyPreBookPage(query) {
  return request({
    url: 'PreBook/WxGetMyPreBookPage',
    method: 'get',
    params: query,
  })
}

// 居民端-撤销预约
export function WxRevokePreBook(data) {
  return request({
    url: 'PreBook/WxRevokePreBook',
    method: 'post',
    data,
  })
}

// 居民端-删除预约单
export function WxDeletePreBook(data) {
  return request({
    url: 'PreBook/WxDeletePreBook',
    method: 'post',
    data,
  })
}

// 撤销订单，居安端居民使用
export function WeRevokeOrder(data) {
  return request({
    url: 'ShopOrder/WeRevokeOrder',
    method: 'post',
    data,
  })
}

// 删除订单，居安养小程序使用
export function WeDeleteOrder(data) {
  return request({
    url: 'ShopOrder/WeDeleteOrder',
    method: 'post',
    data,
  })
}

// <<<<<<< HEAD
// =======
// // 小程序-报名参与活动
// export function WxEnrolPBActivity(data) {
//   return request({
//     url: 'Activity/WxEnrolPBActivity',
//     method: 'post',
//     data: data,
//   })
// }

// >>>>>>> cfd7306b39d45cf808488af8c1dead0ea4f635fa
// 获取党建活动风采（台账）分页列表
export function WxGetPbActElegantPage(query) {
  return request({
    url: 'ActElegant/WxGetPbActElegantPage',
    method: 'get',
    params: query,
  })
  // <<<<<<< HEAD
}
// 获取党建活动风采详情
export function WxGetPbActElegantDetail(query) {
  return request({
    url: 'ActElegant/WxGetPbActElegantDetail',
    method: 'get',
    params: query,
  })
}

// 发布活动记录【党建小程序使用】
export function WxSaveActLogs(data) {
  return request({
    url: 'ActLog/WxSaveActLogs',
    method: 'post',
    data: data,
  })
}
// =======
// }
// >>>>>>> cfd7306b39d45cf808488af8c1dead0ea4f635fa
