<template>
  <div class="my-editor">
    <div ref="editor"
      class="editor-wrapper"></div>
  </div>
</template>

<script>
import WEditor from 'wangeditor'
import { getToken } from '@/utils/auth'
import config from "@/config";

// 富文本编辑器
export default {
  name: 'Editor',
  props: {
    value: {
      default: undefined
    },
    isClear: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      editor: undefined,
      editorHtml: undefined
    }
  },
  watch: {
    isClear (val) {
      // 触发清除文本域内容
      if (val) {
        this.editor.txt.clear()
      }
    },
    value (val) {
      if (val !== this.editorHtml) {
        this.editorHtml = val
        if (val) {
          this.editor.txt.html(val)
        } else {
          this.editor.txt.clear()
        }
      }
    },
    disabled (val) {
      this.editor.$textElem.attr('contenteditable', !this.disabled)
    }
  },
  mounted () {
    this.initEditor()
  },
  methods: {
    getText () {
      this.editor.txt.text()
    },
    setText (val) {
      this.editor.txt.html(val)
    },
    getHtml () {
      this.editor.txt.html()
    },
    initEditor () {
      this.editor = new WEditor(this.$refs.editor)
      this.editor.config.height = 300;
      this.editor.config.zIndex = 0;
      this.editor.config.placeholder = '';
      this.editor.config.focus = false; // 取消自动 focus
      this.editor.config.uploadImgShowBase64 = false // base 64 存储图片
      this.editor.config.showLinkImg = false// 关闭通过图片地址添加图片
      this.editor.config.uploadImgServer = config.apiUploadUrl // 配置上传图片服务器地址
      // const header = {}
      // header[tokenName] = getToken()
      // this.editor.config.uploadImgHeaders = header // 自定义 header
      this.editor.config.uploadFileName = 'image' // 后端接受上传文件的参数名
      this.editor.config.uploadImgMaxSize = 5 * 1024 * 1024 // 将图片大小限制为 5M
      this.editor.config.uploadImgMaxLength = 5 // 限制一次最多上传 5 张图片
      this.editor.config.uploadImgTimeout = 60 * 1000 // 设置超时时间
      this.editor.config.emotions = [
        {
          // tab 的标题
          title: '表情',
          // type -> 'emoji' / 'image'
          type: 'emoji',
          content: [
            '😀',
            '😃',
            '😄',
            '😁',
            '😆',
            '🤣',
            '😄',
            '😅',
            '😆',
            '😉',
            '😊',
            '😋',
            '😎',
            '😍',
            '😘',
            '😗',
            '😚',
            '🙂',
            '🤗',
            '🤔',
            '😳',
            '😑',
            '😶',
            '🙄',
            '😏',
            '😣',
            '😥',
            '😮',
            '🤐',
            '😯',
            '😪',
            '😫',
            '😴',
            '😌',
            '😛',
            '😜',
            '😒',
            '😓',
            '😕',
            '🙃',
            '😲',
            '🙁',
            '😖',
            '😤',
            '😭',
            '😨',
            '😬',
            '😱',
            '😡',
            '😠',
            '😷',
            '🤒',
            '🤧',
            '🤡',
            '😈',
            '💀',
            '👻',
            '👽',
            '💩',
            '😺',
            '😸',
            '😹',
            '😻',
            '😼',
            '😽',
            '🙀',
            '😿',
            '😾',
            '💖',
            '💗',
            '💟',
            '💤',
            '💢',
            '👈',
            '👉',
            '☝',
            '👆',
            '✌',
            '👌',
            '👍',
            '👌',
            '👎',
            '✊',
            '🤛',
            '🤜',
            '👏',
            '🙏',
            '🤝',
            '🌹',
            '🥀'
          ]
        }
      ]
      // // 配置菜单 可根据文档进行添加
      this.editor.config.menus = [
        'head', // 标题
        'bold', // 粗体
        'fontSize', // 字号
        'fontName', // 字体
        'italic', // 斜体
        'underline', // 下划线
        'strikeThrough', // 删除线
        // 'foreColor', // 文字颜色
        // 'backColor', // 背景颜色
        // 'link', // 插入链接
        // 'list', // 列表
        'justify', // 对齐方式
        'quote', // 引用
        'emoticon', // 表情
        'image', // 插入图片
        'table', // 表格
        // 'video',  // 插入视频
        // 'code', // 插入代码
        'undo', // 撤销
        'redo' // 重复
      ]

      this.editor.config.uploadImgHooks = {
        fail: (xhr, editor, result) => {
          // 图片上传并返回结果，但图片插入错误时触发
          this.$message({
            message: '图片插入失败！',
            type: 'error'
          })
        },
        error: function (xhr, editor) {
          // 图片上传出错时触发
          // xhr 是 XMLHttpRequst 对象，editor 是编辑器对象
          this.$message({
            message: '图片上传失败！',
            type: 'error'
          })
        },
        timeout: (xhr, editor) => {
          // 图片上传超时时触发
          this.$message({
            message: '图片上传超时，请检查网络！',
            type: 'error'
          })
        },
        customInsert: (insertImg, data, editor) => {
          const url = data.picurl;
          insertImg(url)
        }
      }
      // 将内容同步到父组件中
      this.editor.config.onchange = html => {
        this.editorHtml = html
        this.$emit('update:value', html)
      }
      this.editor.config.zIndex = 2 // 配置富文本的权重 不然会覆盖其他组件
      // 创建富文本编辑器
      this.editor.create()
      // 初始化值
      if (this.value) {
        this.editorHtml = this.value
        this.editor.txt.html(this.value)
      }
      this.editor.$textElem.attr('contenteditable', !this.disabled)
    }
  }
}
</script>


